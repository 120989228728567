.slick-slider-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 -8px; /* Adjust the negative margin to counteract the default margin on the slides */
  }
  
  .slick-slide-custom {
    box-sizing: border-box;
    padding: 0 8px; /* Adjust padding as needed */
    text-align: center;
  }
  
  .slick-slide-custom img {
    width: 100%; /* Make images fill their container */
    height: 200px; /* Set a fixed height for the images */
    object-fit: cover; /* Maintain aspect ratio while covering the container */
    border-radius: 8px; /* Add rounded corners */
  }
  
  .slick-slide-custom h3 {
    margin-top: 10px; /* Adjust spacing between image and title */
    font-size: 1.5rem; /* Adjust font size for title */
  }
  
  .slick-slide-custom p {
    margin-top: 8px; /* Adjust spacing between title and description */
    font-size: 1rem; /* Adjust font size for description */
    color: #555; /* Set text color */
  }
  .heading-black {
    color: #350905;
  }
  
  .description-black {
    color: black;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .text-black {
    color: #350905;
  }
  
  .hover-description:hover .description-black {
    opacity: 1;
  }
  